import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from 'vue-router';
const LAYOUT = () => import('/@/layouts/index.vue');

import detail from '/@/views/news/detail.vue';

export const routes = [
  {
    path: '/',
    name: 'Root',
    redirect: '/home',
    component: LAYOUT,
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('/@/views/home/index.vue'),
        meta: {
          title: '首页',
        },
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('/@/views/about/index.vue'),
        meta: {
          title: '奥帕资本',
        },
      },
      {
        path: '/business',
        name: 'business',
        component: () => import('/@/views/business/index.vue'),
        meta: {
          title: '业务方向',
        },
      },
      {
        path: 'newsdetail',
        name: 'newsdetail',
        component: () => import('/@/views/news/detail.vue'),
        meta: {
          title: '新闻资讯详情',
          hidden: true,
        },
      },
      {
        path: 'news',
        name: 'news',
        component: () => import('/@/views/news/list.vue'),
        meta: {
          title: '新闻资讯',
        },
      },

      {
        path: 'industryresearchdetail',
        name: 'industryresearchdetail',
        component: () => import('/@/views/industryresearch/detail.vue'),
        meta: {
          title: '行业研究详情',
          hidden: true,
        },
      },
      {
        path: 'industryresearch',
        name: 'industryresearch',
        component: () => import('/@/views/industryresearch/list.vue'),
        meta: {
          title: '行业研究',
        },
      },

      {
        path: '/partners',
        name: 'partners',
        component: () => import('/@/views/partners/index.vue'),
        meta: {
          title: '合作伙伴',
        },
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('/@/views/contact/index.vue'),
        meta: {
          title: '联系我们',
        },
      },
    ],
  },
];
// console.log(process.env.BASE_URL);

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});

router.afterEach((to) => {
  document.title = `AOPA-${to.meta.title}`;
});

export default router;
