import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { RouterView } from 'vue-router';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
export default {
  __name: 'App',
  setup(__props) {
    return (_ctx, _cache) => {
      const _component_el_config_provider = _resolveComponent("el-config-provider");
      return _openBlock(), _createBlock(_component_el_config_provider, {
        locale: _unref(zhCn)
      }, {
        default: _withCtx(() => [_createVNode(_unref(RouterView))]),
        _: 1
      }, 8, ["locale"]);
    };
  }
};